@mixin create-mq($breakpoint, $min-or-max) {
  @if ($breakpoint == 0) {
    @content;
  } @else {
    @media screen and (#{$min-or-max}-width: $breakpoint *1px) {
      @content;
    }
  }
}

@mixin create-col-classes($modifier, $grid-cols, $breakpoint) {
  @include create-mq($breakpoint, "min") {
    @for $i from 1 through $grid-cols {
      $num: ($i/$grid-cols) * 100%;
      &-#{$modifier}-#{$i} {
        flex: 0 0 #{$num};
        max-width: #{$num};
        width: #{$num};
      }
    }
  }
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  &:not(.collapse) {
    margin: 0 -10px;
  }
}

.flex-item {
  flex: 0 0 100%;
  max-width: 100%;
  padding: {
    right: 10px;
    left: 10px;
  }
  &--shrink {
    flex: 0 0 auto;
  }
  &--stretch {
    flex: 1 1 0px;
  }
  .collapse & {
    padding: 0;
  }
}

.item {
  @each $modifier, $breakpoint in $map-grid-props {
    @include create-col-classes($modifier, $grid__cols, $breakpoint);
  }
}

@each $modifier, $breakpoint in $map-grid-props {
  @if ($modifier == "") {
    $modifier: "xs";
  }
  @include create-mq($breakpoint - 1, "max") {
    .hidden-#{$modifier}-down {
      display: none !important;
    }
  }
  @include create-mq($breakpoint, "min") {
    .hidden-#{$modifier}-up {
      display: none !important;
    }
  }
}
