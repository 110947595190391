.footer-item {
  font-size: 11px;
  line-height: 1.5;
  position: relative;
  width: 100%;
  @media screen and (min-width: 576px) {
    padding-bottom: 50px;
    width: auto;
    align-self: stretch;
  }
}

.footer-legal-mark {
  padding: 5px 0;
  width: 250px;
  bottom: 0;
  @media screen and (min-width: 576px) {
    left: 0;
    transform: translateX(0);
  }
}

a {
  text-decoration: underline;
  color: $regular-blue;
}
