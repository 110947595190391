.logo-wrapper {
  @media screen and (min-width: 360px) {
    height: 60px;
  }
  img {
    width: 100%;
    @media screen and (min-width: 360px) {
      height: 100%;
      width: auto;
    }
  }
}

.header-language-selector {
  width: 100px;
  margin: 0 auto;
  @media screen and (min-width: 767px) {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
