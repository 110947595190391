.notification_box {
  position: fixed;
  right: 30px;
  top: 50px;
  width: 250px;
}
.notification_wrapper {
  padding: 16px;
  border-radius: 5px;
  color: $white;
  font-size: 14px;
  font-weight: 600;
  &--error {
    background: $error-color;
  }
  &--success {
    background: $success-color;
  }
}
.notification_header {
  position: relative;
  padding-right: 16px; 
  margin-bottom: 10px;
  .notification_close-btn {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
  }
  svg { fill: $white; }
}