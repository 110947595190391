.h1 {
  font-size: $b-fontSize-h1;
}

.h2 {
  font-size: $b-fontSize-h2;
}

.h3 {
  font-size: $b-fontSize-h3;
}

.h4 {
  font-size: $b-fontSize-h4;
}

.h5 {
  font-size: $b-fontSize-h5;
}

.h6 {
  font-size: $b-fontSize-h6;
}

.h7 {
  font-size: $b-fontSize-h7;
}
