$outline-color: #767676;

button {
  border: none;
  box-shadow: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  font-weight: 600;
}

.g_btn {
  border-radius: 36px;
  font-size: $b-fontSize;
  vertical-align: middle;
  height: 36px;
  width: 136px;
  &:not(:last-child) {
    margin-right: 14px;
  }
  &--primary {
    background: $primary-color;
    color: $white;
    &:hover {
      color: $text-color_primary;
      background: $thermometer-color-green;
    }
  }
  &--disabled {
    background: $outline-color;
    color: $white;
    border: 1px solid $outline-color;
    cursor: not-allowed;
  }
  &-full-width {
    width: 100%;
  }
  &--outlined {
    background: $white;
    color: $primary-color;
    border: 1px solid $primary-color;
    &:hover {
      color: $text-color_primary;
      background: $thermometer-color-green;
      border: 1px solid $text-color_primary;
    }
  }
  &--section {
    //color: $outline-color;
    border: 1px solid;
    min-height: 24px;
    min-width: 136px;
    margin: 6px 0;
    width: unset;
  }
}

.main-cta_btn {
  border-radius: 50px;
  font-size: $b-fontSize;
  vertical-align: middle;
  min-height: 50px;
  min-width: 200px;
  background: $primary-color;
  color: $white;
  &.cta-disabled {
    background: $outline-color;
    color: $white;
    border: 1px solid $outline-color;
    cursor: not-allowed;
  }
  &:not(.cta-disabled):hover {
    color: $text-color_primary;
    background: $thermometer-color-green;
  }
}
