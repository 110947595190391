html,
body {
  padding: 0;
  margin: 0;
}

html {
  font-family: "Avenir";
  font-size: $b-fontSize;
  font-weight: normal;
  color: $text-color_primary;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  background: $bg-color_primary;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  //font-size: inherit;
  font-weight: 600;
}

dl {
  text-align: left;
  margin: 0;
  dd {
    margin: 0;
  }
  &.dl--inline {
    display: flex;
    align-items: center;
  }
}

svg {
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
}

table {
  border-collapse: collapse;
  width: 100%;
}

textarea {
  font: inherit;
  resize: none;
}

p {
  margin: 0;
}
