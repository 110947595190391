.radio-icon {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    border-radius: 50%;
    background: #ffffff;
    border: 1px solid #000000;
}
@media screen and (min-width: 768px) {
    .radio-icon {
        order: 2;
        margin-right: 0;
    }
}

.radio-icon.selected {
    background: #71aa33;
}

.answer-wrapper {
    cursor: pointer;
    user-select: none;
}
