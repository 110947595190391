input,
textarea,
select {
  color: $text-color_primary;
  border: 1px solid $text-color_secondary;
  border-radius: 4px;
  font-size: 14px;
  padding: 8px 6px;
  width: 100%;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: $text-color_additional;
  }
}

select {
  background: transparent;
}

.disabled {
  opacity: 0.5;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-holder {
  letter-spacing: 0.6px;
  label {
    color: $text-color_primary;
    display: block;
    //font-size: 12px;
    line-height: 16px;
    //margin-bottom: 5px;
  }
}

.input-wrapper {
  position: relative;
  input {
    padding-right: 30px;
  }
  > span {
    color: $placeholder-color;
    font-size: 10px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    text-align: center;
    text-transform: uppercase;
  }
}

.switcher-btns--form {
  display: flex;
  flex:  1 1 auto;
  text-align: center;
  span {
    width: 70px;
    border-radius: 4px;
    border: 1px solid #71aa33;
    padding: 2px;
    margin-left: 10px;
    color: #6c757d;
    cursor: pointer;
    &.active {
      background-color: #71aa33;
      color: #ffffff;
    }
  }
}

// Custom checkbox //

.custom-checkbox_wrapper {
  position: relative;
  margin: auto;
  height: 16px;
  width: 16px;
  &.margin-right {
    margin: 0 12px 0 0;
  }
}
.hidden-checkbox {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  line-height: 0;
  margin: 0;
  height: 100%;
  z-index: 2;
}
.pseudo-checkbox_holder {
  border: 1px solid $inputs-border-color;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 0;
  height: 16px;
  width: 16px;
  z-index: 1;
  .hidden-checkbox:checked + & {
    border-color: $primary-color;
    background: $primary-color;
  }
}

.pseudo-checkbox_icon {
  pointer-events: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  visibility: hidden;
  opacity: 0;
  height: 10px;
  width: 11px;
  .hidden-checkbox:checked ~ & {
    visibility: visible;
    opacity: 1;
  }
}

// Custom checkbox end //

// Burger //

.burger_box {
  width: 30px;
  height: 20px;
  cursor: pointer;
  .sidebar-wrapper.active + .main-content-wrapper & {
    .burger_item {
      transform: rotate(42deg) translateY(10px);
      &:before {
        content: none;
      }
      &:after {
        transform: rotate(275deg);
      }
    }
  }
}

.burger_item {
  background: $black;
  border-radius: 3px;
  height: 4px;
  width: 100%;
  // transition: transform 0.2s ease-in-out;
  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 0;
    border-radius: 3px;
    background: $black;
    height: 4px;
    width: 100%;
  }
  &:before {
    top: 50%;
    transform: translate3d(0, -50%, 0);
  }
  &:after {
    top: calc(100% - 4px);
  }
}

// Burger end //
