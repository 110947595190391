$grid__bp-sm: 576;
$grid__bp-md: 768;
$grid__bp-lg: 992;
$grid__bp-xl: 1200;

.hide {
  display: none;
}

.w-100 {
  width: 100%;
}

.t {
  &_uppercase { text-transform: uppercase; }
  &_right { text-align: right; }
  &_left {
    text-align: left;
    &-sm {
      @media screen and (min-width: 576px) {
        text-align: left !important;
      }
    }
  }
  &_center {
    text-align: center;
    &-md {
      @media screen and (min-width: 768px) {
        text-align: center !important;
      }
    }
  }
}

.t {
  &_success {
    color: $success-color;
  }
  &_error {
    color: $error-color;
  }
  &_warning {
    color: $warning-color;
  }
}

.t {
  &_bold {
    font-weight: 700;
  }
  &_normal {
    font-weight: 400;
  }
}

.t {
  &_italic {
    font-style: italic;
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  &:before {
    content: "";
    display: block;
  }
}


.table--colored {
  th {
    color: #4a4a4a;
    font-size: 10px;
    text-transform: uppercase;
    padding: 4px 8px;
  }
  tr {
    text-align: left;
  }
  tbody {
    tr {
      &:nth-child(2n - 1) {
        background: rgba(196, 196, 196, 0.15);
      }
    }
  }
  td {
    color: #424242;
    padding: 4px 8px;
    font-size: 14px;
    line-height: 1.25;
  }
  @media screen and (min-width: 576px) {
  }
}

.dates_range {
  li {
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
  }
  span {
    display: inline-block;
    max-width: 30px;
  }
}

.c-pointer { cursor: pointer; }

.commot-list {  
  font-size: 14px;
  &:not(:last-child) { margin-bottom: 20px; }
  li:not(:last-child) { margin-bottom: 8px; }
}

.common-title {
  font-size: 12px;
  color: #979797;
  margin-bottom: 8px;
}

.no-borders {
  border: none !important;
}

.document-icon {
  height: 18px;
  width: 18px;
  fill: #546e7a;
  &.green {
    fill: #71aa33;
  }
}

.document-title {
  margin: 6px;
}

.margin-me {
  &--top {
    margin-top: 6px;
  }
  &--left {
    margin-left: 6px;
  }
  &--right {
    margin-right: 6px;
  }
  &--btm {
    margin-bottom: 6px;
  }
}

.full-height {
  min-height: 100%;
}

.m {
  &-0 {
    margin: 0;
  }
  &-10 {
    margin: 10px;
  }
  &-20 {
    margin: 20px;
  }
  &-40 {
    margin: 40px;
  }
  &-60 {
    margin: 60px;
  }
}

.mt {
  &-auto {
    margin-top: auto;
  }
  &-0 {
    margin-top: 0;
  }
  &-10 {
    margin-top: 10px;
  }
  &-20 {
    margin-top: 20px;
  }
  &-40 {
    margin-top: 40px;
  }
  &-60 {
    margin-top: 60px;
  }
}

.mb {
  &-auto {
    margin-bottom: auto;
  }
  &-0 {
    margin-bottom: 0;
  }
  &-10 {
    margin-bottom: 10px;
  }
  &-20 {
    margin-bottom: 20px;
  }
  &-40 {
    margin-bottom: 40px;
  }
  &-60 {
    margin-bottom: 60px;
  }
}

.mr {
  &-auto {
    margin-right: auto;
  }
  &-0 {
    margin-right: 0;
  }
  &-10 {
    margin-right: 10px;
  }
  &-20 {
    margin-right: 20px;
  }
  &-40 {
    margin-right: 40px;
  }
  &-60 {
    margin-right: 60px;
  }
}

.ml {
  &-auto {
    margin-left: auto;
  }
  &-0 {
    margin-left: 0;
  }
  &-10 {
    margin-left: 10px;
  }
  &-20 {
    margin-left: 20px;
  }
  &-40 {
    margin-left: 40px;
  }
  &-60 {
    margin-left: 60px;
  }
}

.mh {
  &-auto {
    margin-left: auto;
    margin-right: auto;
  }
  &-0 {
    margin-left: 0;
    margin-right: 0;
  }
  &-10 {
    margin-left: 10px;
    margin-right: 10px;
  }
  &-20 {
    margin-left: 20px;
    margin-right: 20px;
  }
  &-40 {
    margin-left: 40px;
    margin-right: 40px;
  }
  &-60 {
    margin-left: 60px;
    margin-right: 60px;
  }
}

.mv {
  &-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  &-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  &-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  &-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  &-60 {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

.p {
  &-0 {
    padding: 0;
  }
  &-10 {
    padding: 10px;
  }
  &-20 {
    padding: 20px;
  }
  &-40 {
    padding: 40px;
  }
  &-60 {
    padding: 60px;
  }
}

.pt {
  &-0 {
    padding-top: 0;
    &-sm {
      @media screen and (min-width: 576px) {
        padding-top: 0 !important;
      }
    }
    &-md {
      @media screen and (min-width: 768px) {
        padding-top: 0 !important;
      }
    }
    &-xl {
      @media screen and (min-width: 1200px) {
        padding-top: 0 !important;
      }
    }
  }
  &-10 {
    padding-top: 10px;
  }
  &-20 {
    padding-top: 20px;
  }
  &-40 {
    padding-top: 40px;
  }
  &-60 {
    padding-top: 60px;
  }
}

.pb {
  &-0 {
    padding-bottom: 0;
  }
  &-10 {
    padding-bottom: 10px;
  }
  &-20 {
    padding-bottom: 20px;
  }
  &-40 {
    padding-bottom: 40px;
  }
  &-60 {
    padding-bottom: 60px;
  }
}

.pr {
  &-0 {
    padding-right: 0;
  }
  &-10 {
    padding-right: 10px;
  }
  &-20 {
    padding-right: 20px;
  }
  &-40 {
    padding-right: 40px;
  }
  &-60 {
    padding-right: 60px;
  }
}

.pl {
  &-0 {
    padding-left: 0;
  }
  &-10 {
    padding-left: 10px;
  }
  &-20 {
    padding-left: 20px;
  }
  &-40 {
    padding-left: 40px;
  }
  &-60 {
    padding-left: 60px;
  }
}

.ph {
  &-0 {
    padding-left: 0;
    padding-right: 0;
  }
  &-10 {
    padding-left: 10px;
    padding-right: 10px;
  }
  &-20 {
    padding-left: 20px;
    padding-right: 20px;
  }
  &-40 {
    padding-left: 40px;
    padding-right: 40px;
  }
  &-60 {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.pv {
  &-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  &-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  &-20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  &-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  &-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}
