.brain-image {
  height: 40px;
  margin-left: auto;
  margin-bottom: 10px;
  @media all and (min-width: 576px) {
    height: 80px;
    margin-bottom: 0;
    margin-left: 20px;
    order: 2;
  }
  img {
    display: block;
    height: 100%;
  }
}

.header-info-wrapper {
  padding: 0;
  flex-wrap: wrap;
  h2 {
    order: 2;
    @media all and (min-width: 576px) {
      order: 1;
    }
  }
  @media all and (min-width: 576px) {
    height: 80px;
    flex-wrap: nowrap;
  }
}
