.progress-item {
    height: 20px;
    width: 30px;
    background: #c4c4c4;
    margin-right: 5px;
    text-align: center;
    position: relative;
    &:last-child {
        margin-right: 0;
    }
}

.progress-item.is-passed {
    background: #71aa33;
}

.progress-item.is-current {
    background: #407a00;
}

.progress-item.is-current:after {
    border-color: transparent transparent transparent #407a00;
}

.progress-item.is-passed:after {
    border-color: transparent transparent transparent #71aa33;
}

.progress-item:before {
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    left: -2px;
    border-style: solid;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent #fff;
    z-index: 0;
}

@media screen and (min-width: 400px) {
    .progress-item  {
        width: 50px;
    }
}

.progress-item:first-child:before {
    border-color: transparent;
}

.progress-item:after {
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    right: -10px;
    border-style: solid;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent #c4c4c4;
    z-index: 10;
}

.progress-item:after {
    border-left-color: #c4c4c4;
}
